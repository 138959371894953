








// Composition
import { defineComponent, useRouter } from '@nuxtjs/composition-api'

// Store
import { useSearchStore } from '~/store/search'

// Composables
import useSearch from '@/composables/search/useSearch'
import useSearchUrl from '@/composables/search/useSearchUrl'
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'

// Common
import { SEARCH_TYPES } from '@/common/static/search'
import { setSearchHistoryObject } from '@/common/utils/searchHistory'
import { stripHTMLTags, decodeHTMLEntities } from '@/common/utils/characters'
import { QueryStyle } from '~/types/search/searchTypes'

export default defineComponent({
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const searchStore = useSearchStore()
    const { storedSearchInput, setDefaultSearchSettings } = useSearch()
    const { getSearchUrl } = useSearchUrl()
    const router = useRouter()

    const { trackTypeaheadSuggestionClick } = useSearchAnalytics()

    const searchSuggestion = (suggestion: any) => {
      // back to default
      setDefaultSearchSettings(true)

      // set this input
      const strippedTitle = stripHTMLTags(suggestion.title) ?? ''
      searchStore.setSearchInput(encodeURIComponent(strippedTitle))

      // change router
      clearSuggestSelected()

      // send GTM event, only for ga4
      trackTypeaheadSuggestionClick(strippedTitle, SEARCH_TYPES.SUGGESTIONS)

      // Set this in the history search items as well
      setSearchHistoryObject(strippedTitle)

      // Store search input for header
      storedSearchInput.value = strippedTitle

      searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

      // new search
      router.push(getSearchUrl())
    }

    return {
      searchSuggestion,
      decodeHTMLEntities,
    }
  },
})
