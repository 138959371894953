import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import { attachAxiosResponseTimeLoggerInterceptor } from '@/common/utils/logging'
import { ELASTIC_TEMPLATES } from '@/modules/elasticSearch/constants/templates'
import {
  MultiSearchWithTemplateFnParams,
  MultiSearchWithTemplatePayload,
  SearchFnParams,
  SearchWithTemplateFnParams,
  SearchWithTemplatePayload,
} from '@/modules/elasticSearch/types/elasticRequest'

const createElasticSearchPlugin = ({ $axios, $config, $logger, req }: Context) => {
  const logger = $logger.child({ name: 'log:elasticSearch' })
  let path: undefined | string

  if (process.server) {
    path = process.static ? '' : req.url
  }
  if (process.client) {
    path = location.pathname
  }

  // .. Axios related
  const baseURL: string = $config.elasticSearch.pure.elasticSearchBase
  const searchKey: string = $config.elasticSearch.pure.elasticSearchKey

  logger.debug("Creating elasticSearch plugin. BaseUrl: '%s'", baseURL)

  const axios = $axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'ApiKey ' + searchKey,
    },
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  })

  attachAxiosResponseTimeLoggerInterceptor({ axios, path, logger })

  /**
   * Here map env variables to aliases. Alias is like "engine" in app-search.
   * So we will different aliases per use cases.
   */

  const aliases = {
    CITATIONS: $config.elasticSearch.pure.elasticSearchCitationsAlias as string,
  } as const

  // .. Please prefer using template options over regular functions
  const search = async (opt: SearchFnParams<typeof aliases>) => {
    const url = `/${aliases[opt.alias]}/_search`

    try {
      const { data } = await axios.post(url, opt.params)
      return data
    } catch (error) {
      logger.error(error)
    }

    return undefined
  }

  const multiSearch = async (opt: SearchFnParams<typeof aliases>) => {
    const url = `/${aliases[opt.alias]}/_msearch`

    try {
      const { data } = await axios.post(url, opt.params)
      return data
    } catch (error) {
      logger.error(error)
    }

    return undefined
  }

  // .. Template is defined in DataEngineering
  const searchWithTemplate = async (opt: SearchWithTemplateFnParams<typeof aliases>) => {
    const url = `/${aliases[opt.alias]}/_search/template`

    const body: SearchWithTemplatePayload = {
      id: ELASTIC_TEMPLATES[opt.template],
      params: opt.params,
    }

    try {
      const { data } = await axios.post(url, body)
      return data
    } catch (error) {
      logger.error(error)
    }

    return undefined
  }

  const multiSearchWithTemplate = async (opt: MultiSearchWithTemplateFnParams<typeof aliases>) => {
    const url = `/${aliases[opt.alias]}/_msearch/template`

    /**
     * IMPORTANT: This body is suppose to be new line delimited JSON,
     * We need to introduce a new lib to handle that, for now, im leaving
     * here the old regular json structure
     */
    const body: MultiSearchWithTemplatePayload = {
      index: opt.index, // Possibly remap here
      search_templates: opt.search_templates,
    }

    try {
      const { data } = await axios.post(url, body)
      return data
    } catch (error) {
      logger.error(error)
    }

    return undefined
  }

  return { search, searchWithTemplate, multiSearch, multiSearchWithTemplate }
}

export type ElasticSearch = ReturnType<typeof createElasticSearchPlugin>

const plugin: Plugin = (ctx, inject) => {
  inject('elastic', createElasticSearchPlugin(ctx))
}

export default plugin
