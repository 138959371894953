export const DEVKEY = {
  LOGO: 'logo',
  LOGO_1_PERCENT: 'logo_1_percent',
  COPYRIGHT_BACKGROUND: 'copyright_background',
  GLOBAL_HEADER: 'global_header',
  GLOBAL_FOOTER: 'global_footer',
  PRODUCT_INQUIRY: 'inquiry01',
  LEGAL_STATEMENT: 'legal_statement',
  TECH_SUPPORT_PHONE_NUMBER: 'tech_support_phone_number',
  HEADER_LOGO: 'header_logo',
  FOOTER_LOGO: 'footer_logo',
  COPYRIGHT: 'copyright',
  SOCIAL_LINKS: 'social_links',
  PDP_LEGAL_STATEMENT: 'pdp_legal_statement',
  TECHNICAL_SUPPORT: 'technical_support',
  HIDE_PRICE_BRICK: 'hide_pricebrick',
  MTO_PRODUCT_LIST: 'mto_product_list',
  HOMEPAGE: 'homepage_b',
  EXCLUDED_PRODUCTS: 'excluded_products',
  B2D_ALLOWED_COUNTRIES: 'b2d_allowed_countries',
  CHECKOUT_LEGAL_LINKS: 'checkout01',
  CHECKOUT_TROUBLE_CHECKING_OUT_STEP_2_STEP_3: 'checkout02',
  CHECKOUT_TAX_EXEMPT_INSTRUCTIONS: 'checkout03',
  CHECKOUT_FINISH_BUTTON_INFO: 'checkout04',
  CHECKOUT_ORDER_CONFIRMATION: 'checkout05',
  QUOTE_LEGAL_LINKS: 'quote01',
  QUOTE_ORDER_CONFIRMATION: 'quote02',
  LEAD_CONFIRMATION_TERMS_CONDITIONS: 'lead01',
} as const

export function getNavigationQueryParams() {
  const devKeys = [DEVKEY.GLOBAL_HEADER, DEVKEY.GLOBAL_FOOTER]
  return {
    devkeys: devKeys,
    limit: devKeys.length,
    // this can change based on the discussion to the different locale - this is just for navigation
    locale: 'en-us',
    fallbackLocale: true,
  }
}

export const H1_CRAWLER_FIELD_NAME = 'h1'
